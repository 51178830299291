@import 'styles/_theme.scss'

.box
  margin: 0 auto
  padding: 0
  max-width: 1480px
.gap
  height: $height-gap
.content
  display: flex
  margin: 0 auto
  padding: 0
  max-width: 1480px
  min-height: calc(100vh - 130px)
  transition: all ease-in-out .3s
  cursor: default

@media (max-width: calc($width-desktop - 0.1px))
  .box
    max-width: 1040px
  .content
    display: block
    max-width: 1040px

@media (max-width: calc($width-tablet - 0.1px))
  .box
    max-width: 600px
  .gap
    height: $height-gap-1300
  .content
    max-width: 600px
