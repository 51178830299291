$theme: map-get($themes, $setTheme);
$font: map-get($theme, "font");
$fonts: map-get($theme, 'fonts');
$theme-font-path: map-get($theme, 'font-path');
$base-font-path: $theme-font-path;

/**
 * If theme 'font-path' is not the default value, which is relative to the '@psl/themes' directory,
 * it should instead be relative to the project root directory
 */
@if ($theme-font-path != './fonts/') {
  $base-font-path: '../../../#{$theme-font-path}';
}

$eot: ".eot";
$eot-ie: ".eot?#iefix";
$woff: ".woff";
$woff2: ".woff2";
$ttf: ".ttf";
$svg: ".svg";

@mixin font-face($family, $weight, $fonts1, $fonts2, $style) {
  @font-face {
    font-family: $family;
    src: url('#{$base-font-path}#{$fonts1}#{$eot}');
    src:
      url('#{$base-font-path}#{$fonts1}#{$eot-ie}') format('embedded-opentype'),
      url('#{$base-font-path}#{$fonts1}#{$woff2}') format('woff2'),
      url('#{$base-font-path}#{$fonts1}#{$woff}') format('woff'),
      url('#{$base-font-path}#{$fonts1}#{$ttf}') format('truetype'),
      url('#{$base-font-path}#{$fonts1}#{$svg}#{$fonts2}') format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

@mixin loadFonts($font-map, $font){
  @each $key, $weight in $font-map {
    $font-regular: '#{$font}-#{$weight}-webfont';
    $font-svg: '##{$font}#{$weight}';
    $font-italic: '#{$font}-#{$weight}italic-webfont';
    @if ($weight == 'regular') {
      $font-italic: '#{$font}-italic-webfont';
    }
    $font-italic-svg: '##{$font}_italic';
    @include font-face($font, $key, $font-regular, $font-svg, normal);
    @include font-face($font, $key, $font-italic, $font-italic-svg, italic);
  }
}

@each $font, $map in $fonts {
  @include loadFonts($map, $font);
}

@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}
