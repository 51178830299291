@import ../../../themes/partials/typography-theme
@include typography-t-($setTheme)

.display1
  @extend .typography-root-t-#{$setTheme}
  @extend .typography-display1-t-#{$setTheme}

.title
  @extend .typography-root-t-#{$setTheme}
  @extend .typography-title-t-#{$setTheme}

.subheading
  @extend .typography-root-t-#{$setTheme}
  @extend .typography-subheading-t-#{$setTheme}

.menu-item
  @extend .typography-root-t-#{$setTheme}
  @extend .typography-menu-item-t-#{$setTheme}

.thumb-label
  @extend .typography-root-t-#{$setTheme}
  @extend .typography-thumb-label-t-#{$setTheme}

.section
  margin: 1.5em 0

.body1
  @extend .typography-root-t-#{$setTheme}
  @extend .typography-body1-t-#{$setTheme}

.logo
  @extend .typography-root-t-#{$setTheme}
  @extend .typography-logo-t-#{$setTheme}

.button
  @extend .font-t-#{$setTheme}
  @extend .typography-button-t-#{$setTheme}

.link
  @extend .typography-root-t-#{$setTheme}
  @extend .typography-link-t-#{$setTheme}

.errorElement
  @extend .typography-root-t-#{$setTheme}
  @extend .typography-error-element-t-#{$setTheme}

.left
  @extend .typography-left-t-#{$setTheme}

.right
  @extend .typography-right-t-#{$setTheme}

.center
  @extend .typography-center-t-#{$setTheme}

.justify
  @extend .typography-justify-t-#{$setTheme}

.noWrap
  @extend .typography-no-wrap-t-#{$setTheme}

.gutter
  @extend .typography-gutter-t-#{$setTheme}

.paragraph
  @extend .typography-paragraph-t-#{$setTheme}

.primary
  @extend .typography-primary-t-#{$setTheme}

.secondary
  @extend .typography-secondary-t-#{$setTheme}

.text-primary
  @extend .typography-text-primary-t-#{$setTheme}

.text-secondary
  @extend .typography-text-secondary-t-#{$setTheme}

.tertiary
  @extend .typography-tertiary-t-#{$setTheme}

.error
  @extend .typography-error-t-#{$setTheme}

.inherit
  @extend .typography-inherit-t-#{$setTheme}

.uppercase
  text-transform: uppercase