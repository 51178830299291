@import 'styles/_theme.scss'

.box
  margin: 0 auto
  padding: 30px 80px
  // max-width: 1920px
  .content
    position: inherit
  h1
    font-size: 30px
    font-weight: 600
    color: #000000
    margin: 0 0 20px 0
    line-height: 36px
    opacity: 100%
    position: inherit
    font-family: "PT Sans", sans-serif
  h2
    font-size: 24px
    font-weight: 600
    color: #000000
    margin: 0 0 30px 0
    line-height: 30px
    opacity: 100%
    position: inherit
    font-family: "PT Sans", sans-serif
  p
    font-size: 18px
    font-weight: 400
    margin: 0 0 20px 0
    padding: 0
    line-height: 24px
    opacity: 100%
    position: inherit
    font-family: "PT Sans", sans-serif
  a
    color: #3b78c3
    opacity: 100%
    text-decoration: underline
    transition: all ease-in-out .3s
  a:hover
    color: #3b78c3

@media (max-width: calc($width-desktop - 0.1px))
  .box
    padding: 24px 0

@media (max-width: calc($width-tablet - 0.1px))
  .box
    padding: 24px 20px

@media (max-width: $width-mobile)
  .box
    padding: 20px
