@import '../main';
@import './font-theme';

@mixin underline($type) {
  @if(map-get($type, 'underline') == 'always') {
    text-decoration: underline;
  } @else if(map-get($type, 'underline') == 'none') {
    text-decoration: none;
  }
  &:hover {
    @if(map-get($type, 'underline') == 'hover') {
      text-decoration: underline;
    }
  }
}

@mixin typography-t-($name) {
  @include font-theme($name);
  $theme: map-get($themes, $name);
  $text: map-get($theme, 'text');
  $primary-text: map-get($text, 'primary');
  $secondary-text: map-get($text, 'secondary');
  $colors: map-get($theme, 'colors');
  $primary-color: map-get($colors, 'primary');
  $secondary-color: map-get($colors, 'secondary');
  $tertiary-color: map-get($colors, 'tertiary');
  $action-color: map-get($colors, 'action');
  $error-color: map-get($colors, 'error');
  $default-color: map-get($colors, 'default');
  $typography: map-get($theme, 'typography');
  $title: map-get($typography, 'title');
  $button: map-get($typography, 'button');
  $link: map-get($typography, 'link');
  $error: map-get($typography, 'error');
  $display1: map-get($typography, 'display1');
  $logo: map-get($typography, 'logo');
  $body1: map-get($typography, 'body1');
  $menu-item: map-get($typography, 'menu-item');
  $thumb-label: map-get($typography, 'thumb-label');
  $subheading: map-get($typography, 'subheading');
  .typography-root-t-#{$name} {
    @extend .font-t-#{$name};
    margin: 0;
    display: block;
  }

  .typography-display1-t-#{$name} {
    font: {
      size: map-get($display1, 'size');
      weight: map-get($display1, 'weight');
      style: map-get($display1, 'style');
      @if (map-get($display1, 'family')) {
        family: map-get($display1, 'family');
      }
    }
    text-transform: map-get($display1, 'text-transform');
    line-height: map-get($display1, 'line-height');
    margin-left: -.04em;
    @extend .typography-#{map-get($display1, 'color')}-t-#{$name};
    @include underline($display1);
  }

  .typography-logo-t-#{$name} {
    font: {
      size: map-get($logo, 'size');
      weight: map-get($logo, 'weight');
      style: map-get($logo, 'style');
      @if (map-get($logo, 'family')) {
        family: map-get($logo, 'family');
      }
    }
    text-transform: map-get($logo, 'text-transform');
    line-height: map-get($logo, 'line-height');
    @extend .typography-#{map-get($logo, 'color')}-t-#{$name};
    @include underline($logo);
  }

  .typography-title-t-#{$name} {
    font: {
      size: map-get($title, 'size');
      weight: map-get($title, 'weight');
      style: map-get($title, 'style');
      @if (map-get($title, 'family')) {
        family: map-get($title, 'family');
      }
    }
    text-transform: map-get($title, 'text-transform');
    line-height: map-get($title, 'line-height');
    @extend .typography-#{map-get($title, 'color')}-t-#{$name};
    @include underline($title);
  }

  .typography-subheading-t-#{$name} {
    font: {
      size: map-get($subheading, 'size');
      weight: map-get($subheading, 'weight');
      style: map-get($subheading, 'style');
      @if (map-get($subheading, 'family')) {
        family: map-get($subheading, 'family');
      }
    }
    text-transform: map-get($subheading, 'text-transform');
    line-height: map-get($subheading, 'line-height');
    @extend .typography-#{map-get($subheading, 'color')}-t-#{$name};
    @include underline($subheading);
  }

  .typography-menu-item-t-#{$name} {
    font: {
      size: map-get($menu-item, 'size');
      weight: map-get($menu-item, 'weight');
      style: map-get($menu-item, 'style');
      @if (map-get($menu-item, 'family')) {
        family: map-get($menu-item, 'family');
      }
    }
    text-transform: map-get($menu-item, 'text-transform');
    line-height: map-get($menu-item, 'line-height');
    margin: {
      bottom: 3px;
      top: 3px;
    }
    @extend .typography-#{map-get($menu-item, 'color')}-t-#{$name};
    @include underline($menu-item);
  }

  .typography-thumb-label-t-#{$name} {
    font: {
      size: map-get($thumb-label, 'size');
      weight: map-get($thumb-label, 'weight');
      style: map-get($thumb-label, 'style');
      @if (map-get($thumb-label, 'family')) {
        family: map-get($thumb-label, 'family');
      }
    }
    text-transform: map-get($thumb-label, 'text-transform');
    line-height: map-get($thumb-label, 'line-height');
    @extend .typography-#{map-get($thumb-label, 'color')}-t-#{$name};
    @include underline($thumb-label);
  }

  .typography-body1-t-#{$name} {
    font: {
      size: map-get($body1, 'size');
      weight: map-get($body1, 'weight');
      style: map-get($body1, 'style');
      @if (map-get($body1, 'family')) {
        family: map-get($body1, 'family');
      }
    }
    text-transform: map-get($body1, 'text-transform');
    line-height: map-get($body1, 'line-height');
    @extend .typography-#{map-get($body1, 'color')}-t-#{$name};
    @include underline($body1);
  }

  .typography-button-t-#{$name} {
    font: {
      size: map-get($button, 'size');
      weight: map-get($button, 'weight');
      style: map-get($button, 'style');
      @if (map-get($button, 'family')) {
        family: map-get($button, 'family');
      }
    }
    text-transform: map-get($button, 'text-transform');
    line-height: map-get($button, 'line-height');
    @extend .typography-#{map-get($button, 'color')}-t-#{$name};
    @include underline($button);
  }

  .typography-link-t-#{$name} {
    @extend .font-t-#{$name};
    font: {
      size: map-get($link, 'size');
      weight: map-get($link, 'weight');
      style: map-get($link, 'style');
      @if (map-get($link, 'family')) {
        family: map-get($link, 'family');
      }
    }
    text-transform: map-get($link, 'text-transform');
    text-decoration: none;
    display: inline-block;
    padding: 0.25em 0;
    margin: 0.25em 0;
    cursor: pointer;
    line-height: map-get($link, 'line-height');
    @extend .typography-#{map-get($link, 'color')}-t-#{$name};
    @include underline($link);
    // Prevent focus zoom in iOS
    @media
    (-webkit-min-device-pixel-ratio: 2) and (max-device-width: 480px) {
      font-size: 16px;
      padding: 0.75em 0;
    }
  }

  .typography-error-element-t-#{$name} {
    @extend .font-t-#{$name};
    font: {
      size: map-get($error, 'size');
      weight: map-get($error, 'weight');
      style: map-get($error, 'style');
      @if (map-get($error, 'family')) {
        family: map-get($error, 'family');
      }
    }
    text-transform: map-get($error, 'text-transform');
    line-height: map-get($error, 'line-height');
    @extend .typography-#{map-get($error, 'color')}-t-#{$name};
    @include underline($error);
  }

  .typography-left-t-#{$name} {
    text-align: left;
  }

  .typography-right-t-#{$name} {
    text-align: right;
  }

  .typography-center-t-#{$name} {
    text-align: center;
  }

  .typography-justify-t-#{$name} {
    text-align: justify;
  }

  .typography-no-wrap-t-#{$name} {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .typography-gutter-t-#{$name} {
    margin-bottom: 0.35em;
  }

  .typography-paragraph-t-#{$name} {
    margin-bottom: 16px;
  }
  .typography-secondary-text-t-#{$name} {
    color: $secondary-text;
  }
  .typography-primary-text-t-#{$name} {
    color: $primary-text;
  }
  .typography-inherit-text-t-#{$name} {
    color: inherit;
  }
  .typography-knockout-t-#{$name} {
    color: #ffffff;
  }

  /**
    These colors must come after the `[color]-text` classes to allow color prop
    override of colors initially set from above color classes in type classes
   */

  .typography-error-t-#{$name} {
    color: $error-color;
  }
  .typography-default-t-#{$name} {
    color: $default-color;
  }
  .typography-primary-t-#{$name} {
    color: $primary-color;
  }

  .typography-secondary-t-#{$name} {
    color: $secondary-color;
  }

  .typography-tertiary-t-#{$name} {
    color: $tertiary-color;
  }
  .typography-action-t-#{$name} {
    color: $action-color;
  }
  .typography-inherit-t-#{$name} {
    color: inherit;
  }
  .typography-text-primary-t-#{$name} {
    color: $primary-text;
  }
  .typography-text-secondary-t-#{$name} {
    color: $secondary-text;
  }
}
