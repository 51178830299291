@import 'styles/_theme.scss'

.back
  position: relative
  cursor: pointer
  width: 50px
  padding: 0
  border: none
  background: none
  &:hover
    text-decoration: none
.backTxt
  color: #979797 !important
  font-size: 14px !important
  font-weight: bold !important
  line-height: 17px !important
  margin-bottom: 0 !important

@media (max-width: calc($width-tablet - 0.1px))
  .back
    margin: 0
