@import 'styles/_theme.scss'

.card
  display: flex
  margin: 20px 0
  gap: 15px 
.img
  width: 100px
  border: solid 1px $color-secondary
.detail
  display: flex
  flex-direction: column
  gap: 0 
.title
  display: block
  font-family: "PT Sans", sans-serif
  font-weight: bold
  font-size: 16px
  line-height: 20px
  margin: 0 0 10px 0
  color: #3B78C3
  transition: all ease-in-out .3s
  &:hover
    color: #08487d
.description
  font-family: "PT Sans", sans-serif
  color: #000
  font-size: 14px
  line-height: 18px
.credit
  font-family: "PT Sans", sans-serif
  color: #707070
  font-size: 12px
  line-height: 18px
.readmore
  font-size: 14px
  line-height: 18px
  margin-left: 5px
  cursor: pointer
  text-wrap: nowrap
  color: #3B78C3
  text-decoration: underline
  transition: all ease-in-out .3s
  &:hover
    color: #08487d
    text-decoration: none

@media (max-width: calc($width-desktop - 0.1px))
  .card
    &:last-of-type
      margin-bottom: 0
