.container
  padding: 20px

.interstitialContainer
  display: flex
  align-items: center
  justify-content: center

.interstitial
  margin: auto 20px !important

.button
  right: 0
  top: 0
  position: absolute
  z-index: 2

.modalBody
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0

.content
  z-index: 1
  position: relative

.interstitialContent
  display: flex
  justify-content: center
  align-items: center
  z-index: 1
  background-color: white
  height: 100%

.closeLabel
  color: white
  text-decoration: underline white
  font-weight: bold
  cursor: pointer

.bottomText
  color: #9D9D9D
  font-weight: bold