@import 'styles/_theme.scss'

.box
  margin: 0 auto
  padding: 30px 80px
  // max-width: 1920px
  h3
    font-size: 22px
    line-height: 26px
    font-weight: bold
    margin-bottom: 30px
    color: $color-header
  p
    font-size: 16px
    line-height: 20px
  ul
    font-size: 16px
    line-height: 20px
    margin-top: 20px
  li
    margin-bottom: 5px

.form
  display: flex
  flex-direction: column
  margin: 30px 0
  gap: 0

.modal
  font-family: 'PT Sans', sans-serif
  hr
    color: #fff
    height: 2px
  p
    font-weight: 400
    line-height: 1.5
    margin: 0px 0px 16px
  &:focus-visible
    outline: none

.back
  margin-top: 30px
  padding: 0
  cursor: pointer
  width: fit-content
  border: none
  background: none

@media (max-width: calc($width-desktop - 0.1px))
  .box
    padding: 24px 40px
  .back
    margin-top: 0

@media (max-width: calc($width-tablet - 0.1px))
  .box
    padding: 24px 20px
  .back
    position: relative

@media (max-width: $width-mobile)
  .box
    padding: 20px
  h3
    font-size: 18px
    margin-bottom: 12px

  .form
    margin-top: 20px
    span
      font-size: 16px
