.formio-component-form .formio-form {
  font-family: 'PT Sans', sans-serif !important;
}

.formio-component-form .form-group {
  margin-bottom: 20px !important;
}

.formio-component-form label {
  color: #000 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  height: unset !important;
  margin: 0 0 5px 0 !important;
  padding: 0 !important;
}

.formio-component-form .field-required:after {
  content: ' *' !important;
  color: red !important;
  font-style: normal !important;
}

.formio-component-form input.form-control {
  border: 1px solid #cecece !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  height: 38px !important;
  padding: 0 0 0 8px !important;
  box-shadow: none !important;
}

.formio-component-form input.form-control:hover {
  border: unset !important;
}

.formio-component-form input.form-control:focus {
  border: 1px solid #3b78c3 !important;
}

.formio-component-form .dropdown {
  border: 1px solid #cecece !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  color: #747474 !important;
  width: 100% !important;
}

.formio-component-form .choices {
  border-radius: 5px !important;
  box-shadow: none !important;
  margin-bottom: 0 !important;
}

.formio-component-form .choices:hover {
  border: 1px solid #3b78c3 !important;
  border-width: 1px !important;
  margin-bottom: -1px !important;
}

.formio-component-form input[type=checkbox] {
  margin-top: 3px !important;
}

.formio-component-form .choices:focus {
  border: 1px solid #3b78c3 !important;
}

.formio-component-form .formio-component-checkbox .form-check span {
  font-size: 16px !important;
}

.formio-component-form button:nth-last-child(3) {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: fit-content !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  height: 38px !important;
  color: #fff !important;
  margin: 5px 0 0 0 !important;
  padding: 12px 24px !important;
  background: #3b78c3 !important;
  border: 1px solid #3b78c3 !important;
  border-radius: 20px !important;
  box-shadow: none !important;
  transition: all 0.3s ease-in-out !important;
}

.formio-component-form button:nth-last-child(3):hover {
  background: #064b69 !important;
  border: 1px solid #1185b9 !important;
}

.formio-component-form .form-control.is-invalid {
  border: 1px solid red !important;
}

.formio-component-form .error {
  text-align: left !important;
  font-size: 15px !important;
  color: red !important;
  margin-top: 5px !important;
}
