@import 'styles/_theme.scss'
  
.badge
  padding: 5px 15px 5px
  margin: 0 10px
  border-radius: 30px
  background-color: rgba(59, 120, 195, 0.1)

.icon
  width: 24px
  height: 24px
  margin: 1px 0
  float: left

.text
  font-size: 12px
  line-height: 18px
  color: $color-badge
