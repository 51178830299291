.loadingMask
  position: fixed
  top: 0
  width: 100vw
  height: 100vh
  background-color: #ffffff85
  z-index: 1000

.circularProgress
  position: fixed
  left: 45%
  top: 50%