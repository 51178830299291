@import 'styles/_theme.scss'

.container
  margin: 21px 20px 20px

.h2
  font-family: "PT Sans", sans-serif
  font-weight: bold
  font-size: 22px
  line-height: 26px
  color: #08487D
  text-wrap: nowrap
  margin: 0

.dropdown
  width: 100%
  margin-top: -4px
  margin-left: 20px
  font-size: 14px
  font-weight: bold
  font-family: 'PT Sans', sans-serif
  color: $color-header

.noresult
  margin: 50px auto
  text-align: center
  font-size: 18px
  line-height: 30px
  color: $color-text

.anchor
  display: block
  position: relative
  top: -80px
  visibility: hidden

@media (max-width: calc($width-desktop - 0.1px))
  .container
    margin: 21px 0

@media (max-width: calc($width-tablet - 0.1px))
  .container
    margin: 40px 20px

  .anchor
    top: -165px
