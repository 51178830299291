@import 'styles/_theme.scss'

.headerMenu
  width: 360px
  top: 80px
  right: -440px
  height: 100%
  background: #fff
  position: fixed
  transition: all ease-in-out .3s
  box-shadow: -3px 5px 10px #00000029
  visibility: hidden

.headerOpen
  right: calc(50vw - 740px)
  visibility: visible

.headerContainer
  padding: 23px 30px
  text-align: left
.menuSubHeader
  font-size: 16px
  line-height: 18px
  font-weight: 600
.menuRow
  color: #08487d
  fill: #08487d
  line-height: 22px
  padding-top: 19px
  font-size: 16px
  font-weight: normal
  cursor: pointer
  text-decoration: none
  transition: all ease-in-out .3s
  &:hover
    font-weight: bold
    text-decoration: none
    color: #3b78c3
    fill: #3b78c3
  &:first-of-type
    padding-top: 0
  svg
    margin-right: 10px
    vertical-align: bottom
.menuDivider
  background: $color-grey
  height: 1px
  margin: 20px 0

.iconContainer
  margin-top: 15px
  display: flex
  width: 250px
  flex-direction: row
  flex-wrap: wrap
  gap: 15px
  svg
    fill: #08487d
    width: 30px
    height: 30px
    transition: all ease-in-out .3s
    text-decoration: none
    &:hover
      fill: #3b78c3
      text-decoration: none

.svgIcon
  cursor: pointer


@media (max-width: calc($width-desktop - 0.1px))
  .headerOpen
    right: calc(50vw - 520px)

@media (max-width: calc($width-tablet - 0.1px))
  .headerMenu
    margin: 0 auto
    top: 80px
    right: calc((100vw - 600px) / 2)
    width: 100%
    max-width: 600px
    height: unset
    max-height: 0px
    font-size: 14px
    visibility: hidden
    transition: all 0.5s ease-in-out
    z-index: 999
  .headerOpen
    top: 80px
    width: 100%
    max-width: 600px
    max-height: 100vh
    visibility: visible
  .headerContainer
    opacity: 0%
    transition: all 0.5s ease-in-out
  .headerContainerOpen
    opacity: 100% !important

@media (max-width: $width-mobile)
  .headerMenu
    right: 0
