// Color
$color-primary: #08487d;
$color-secondary: #3b78c3;
$color-header: #08487d;
$color-subheader: #3b78c3;
$color-text: #000000;
$color-background: #FFFFFF;
$color-badge: #205b8c;
$color-category: #6a95b9;

$color-logo: #1B70D0;
$color-button: #23A8AF;
$color-button2: #09597D;
$color-link: #23A8AF;
$color-link2: #09597D;

$color-brand: #0077BE;
$color-brand2: #6E6E6E;
$color-blue: #161A2E;
$color-blue2: #BFD7E9;
$color-blue3: #F2F8FC;
$color-grey: #CECECE;
$color-grey2: #F3F3F3;
$color-grey3: #FAFAFA;
$color-highlight: #00B915;
$color-highlight2: #CD1719;
$color-interactive: #3b78c3;
$color-interactive2: #08487d;
$color-black: #000000;
$color-white: #FFFFFF;

// Header
$height-gap: 80px;
$height-gap-962: 80px;
$height-gap-768: 120px;
$height-gap-1300: 145px;

// Width
$width-mobile: 600px;
$width-tablet: 1040px;
$width-desktop: 1480px;

// Form pages
$margin-content-form: 80px 130px;
$margin-content-form-768: 30px 40px 60px;
$margin-content-form-600: 30px 20px;
