@import 'styles/_theme.scss'

.bg
  width: 100%
  max-width: 1480px
  margin: 0 auto
  background: #FFF
  // position: absolute
  z-index: 100
.box
  height: 50px
  width: 100%
  max-width: 1480px
  margin: 0 auto
  padding: 16px 30px
  border: solid 1px #e5e4e3
  background-color: #FFF
  z-index: 100
  opacity: 100%
  position: fixed
  bottom: 0

.footer
  text-align: center

.imgSocial
  width: 18px
  height: 18px
  margin-top: -2px
  margin-left: 15px

.a
  display: inline-block
  margin: 0 10px
  color: $color-subheader
  font-size: 14px
  line-height: 18px
  font-family: "PT Sans", sans-serif
  transition: all ease-in-out .3s

.pcopy
  color: #000
  display: block
  font-size: 14px
  line-height: 18px
  font-family: "PT Sans", sans-serif
  margin: 0 150px 0 0
.psocial
  width: 170px
  color: #000
  display: block
  font-size: 14px
  line-height: 18px
  font-family: "PT Sans", sans-serif
  margin: 0
  float: left
.plink
  color: $color-header
  display: block
  font-size: 16px
  font-family: "PT Sans", sans-serif
  line-height: 20px
  margin: 0
  padding: 0

.mbox
  display: none

@media (max-width: calc($width-desktop - 0.1px))
  .bg
    max-width: 1040px
  .box
    max-width: 1040px
  .mbox
    display: none
  .pcopy
    margin: 0
  .psocial
    width: 410px

@media (max-width: calc($width-tablet - 0.1px))
  .bg
    max-width: 600px
    padding-bottom: 69px
  .box
    max-width: 600px
    display: flex
    flex-direction: column-reverse
    align-items: center
    position: relative
    height: 100px
    bottom: 0
  .footer
    padding: 0
  .mbox
    display: flex
    flex-direction: row
    position: fixed
    bottom: 0
    width: 100%
    max-width: 600px
    height: 69px
    padding: 15px 30px 11px
    background-color: #f4f7fb
    border: solid 1px #e5e4e3
    text-align: center
    z-index: 120
    gap: 35px
  .mbutton
    border: none
    flex-grow: 1
    background-color: transparent
    color: #3B78C3
  .micon
    height: 20px
    margin-top: -10px
  .mtext
    font-size: 16px
    line-height: 22px
    margin-top: -5px
  .psocial
    float: unset
    margin-top: 11px
    width: fit-content

@media (max-width: 440px)
  .mbox
    gap: 0
