@import 'styles/_theme.scss'

.container
  margin: 24px auto 0
  width: 520px

.h2
  font-size: 20px
  line-height: 24px
  font-weight: bold
  color: #000

.button
  display: flex
  align-items: center
  justify-content: center
  border: 1px solid #3b78c3
  background: #3b78c3
  border-radius: 20px
  color: #fff
  height: 38px
  font-size: 16px
  font-weight: bold
  margin: 0 0 10px 0
  padding: 1px 15px 1px 25px
  opacity: 1
  transition: all ease-in-out .3s
  &:hover
    color: #3b78c3
    background: #FFF
    border: 1px solid #3b78c3
.disable
  opacity: 0.5
.buttonIcon
  height: 33px
  width: 33px
  color: #fff
  &:hover
    color: #3b78c3

@media (max-width: calc($width-desktop - 0.1px))
  .button
    margin: 0

@media (max-width: calc($width-tablet - 0.1px))
  .container
    margin: 24px 20px 0 20px
    width: unset
