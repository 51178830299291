@import themes/partials/font-theme
@import themes/partials/snackbar-theme
@import styles/_theme.scss

@include snackbar-theme($setTheme)
@include font-theme($setTheme)

.snackbar
  @extend .font-t-#{$setTheme}
  @extend .snackbar-t-#{$setTheme}
  margin: 0 auto
  width: 600px
  min-height: 39px
  z-index: 200
  @media (max-width: calc($width-desktop - 0.1px))
    right: calc(50vw - 520px)
    left: unset
  @media (max-width: calc($width-tablet - 0.1px))
    width: 600px
    right: unset
    left: calc((100vw - 600px)/2)
  @media (max-width: $width-mobile)
    width: 100%
    right: unset
    left: unset

.button
  right: 0
  top: 0
  position: absolute

.error
  @extend .snackbar-error-t-#{$setTheme}

.top
  @extend .snackbar-top-t-#{$setTheme}

.floating
  @extend .snackbar-floating-t-#{$setTheme}

.bottom
  @extend .snackbar-bottom-t-#{$setTheme}
