@import './_theme.scss'

main
  display: block // Enable <main> element in IE

html
  scroll-behavior: smooth

a
  color: $color-interactive
  opacity: 100%
  &:hover
    color: $color-interactive2
    text-decoration: none
