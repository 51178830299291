@import 'styles/_theme.scss'

.container
  width: 440px
  // flex: 2 314px
  position: fixed
  background-color: #f4f7fb
  transition: all ease-in-out .3s
  scrollbar-width: none
  &::-webkit-scrollbar
    display: none

.content
  min-height: 280px
  padding: 0 0 50px
  overflow: auto
  position: relative
  height: calc(100vh - 80px)
  scrollbar-width: none
  &::-webkit-scrollbar
    display: none
  &:not(:last-child)
    border-bottom: solid 2px #e5e4e3

.menu
  width: 100%
  margin: 0
  padding: 15px 20px
  line-height: 20px
  background-color: #205B8C26

.menuC
  width: 100%
  margin: 0 0 4px
  padding: 15px 20px

.menuTitle
  font-family: "PT Sans", sans-serif
  font-weight: bold
  font-size: 16px
  line-height: 20px
  color: #08487D

.menuText
  font-family: "PT Sans", sans-serif
  font-size: 16px
  line-height: 20px
  color: #000

.link
  font-family: "PT Sans", sans-serif
  font-size: 16px
  line-height: 20px
  margin-bottom: 19px
  color: #3B78C3
  transition: all ease-in-out .3s
  &:hover
    color: #08487d

.moreText
  font-size: 16px
  line-height: 22px
  float: right
  color: #3B78C3
  cursor: pointer
  transition: all ease-in-out .3s
  &:hover
    text-decoration: none
    color: #08487d

.span
  font-size: 16px
  line-height: 22px
  margin-bottom: 19px
  color: $color-header

.title
  font-family: "PT Sans", sans-serif
  font-weight: bold
  font-size: 14px
  line-height: 18px
  margin-top: 6px
  color: #000
.info
  font-family: "PT Sans", sans-serif
  font-weight: normal
  font-size: 14px
  line-height: 18px
  color: #000

@media (max-width: calc($width-tablet - 0.1px))
  .container
    display: none
    flex: none
    width: 0
    opacity: 0
