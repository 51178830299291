@import 'styles/_theme.scss'

.container
  position: relative
  width: 440px
  border-left: solid 1px #e5e4e3
  margin-left: 1040px
  margin-bottom: 50px
  overflow: auto
  scrollbar-width: thin


@media (max-width: calc($width-desktop - 0.1px))
  .container
    position: relative
    width: 600px
    margin-left: 440px
    border-left: none
    padding: 0 40px 20px
    height: unset
    overflow: unset

@media (max-width: calc($width-tablet - 0.1px))
  .container
    width: 100%
    margin-left: 0
    margin-bottom: 0
    padding: 0 0 20px
