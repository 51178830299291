@import 'styles/_theme.scss'

.container
  display: flex
  width: 370px
  min-width: 240px
  height: 40px
.input
  width: 100%
  height: 100%
  border: solid 1px #fff
  border-radius: 20px
  font-size: 16px
  line-height: 22px
  background-color: transparent
  text-indent: 13px
  color: #fff
  transition: all ease-in-out .3s
  &::placeholder
    color: rgba(255, 255, 255, 0.6)
  &:focus
    outline: none
.searchimg
  height: 20px
  width: 20px
  cursor: pointer
  transition: all ease-in-out .3s
  &:hover
    fill: #064B69
  &:active
    fill: #161A2E

@media (max-width: calc($width-tablet - 0.1px))
  .container
    width: 580px

@media (max-width: calc($width-mobile - 0.1px))
  .container
    width: calc(100vw - 20px)
