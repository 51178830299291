@import 'styles/_theme.scss'

.container
  position: fixed
  width: 600px
  margin-left: 440px
  margin-bottom: 50px
  height: calc(100vh - 80px - 50px)
  overflow: auto
  scrollbar-width: none
  &::-webkit-scrollbar
    display: none

@media (max-width: calc($width-desktop - 0.1px))
  .container
    position: relative
    margin-left: 440px
    padding: 0 40px
    overflow: auto
    height: auto

@media (max-width: calc($width-tablet - 0.1px))
  .container
    width: 100%
    margin-left: 0
    margin-bottom: 0
    padding: 0
