@import 'styles/_theme.scss'

.box
  margin: 0 auto
  padding: 30px 80px 60px
  h1
    font-size: 22px
    line-height: 26px
    color: #08487d
    font-weight: bold
    margin-bottom: 17px
  p
    font-size: 18px
    line-height: 22px
    color: #000
    margin-bottom: 22px

.logos
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 40px
.img
  max-width: 100%
  height: auto

.back
  margin-top: 30px
  padding: 0
  cursor: pointer
  width: fit-content
  border: none
  background: none

.hideMobile
  display: block

@media (max-width: calc($width-desktop - 0.1px))
  .box
    padding: 24px 40px

@media (max-width: calc($width-tablet - 0.1px))
  .box
    padding: 24px 20px
  .back
    position: relative
  .hideMobile
    display: none

@media (max-width: $width-mobile)
  .box
    padding: 20px
  .logos
    justify-content: space-around
