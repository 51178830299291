@import 'styles/_theme.scss'

.box
  margin: 0 auto
  padding: 0
  max-width: 1480px
  background-color: $color-background
.gap
  height: $height-gap
.content
  display: flex
  min-height: 500px
  cursor: default
  z-index: 20
  overflow-x: hidden
  transition: all ease-in-out .3s

@media (max-width: calc($width-desktop - 0.1px))
  .box
    max-width: 1040px
  .content
    display: block
    max-width: 1040px

@media (max-width: calc($width-tablet - 0.1px))
  .box
    max-width: 600px
    // height: calc(100vh - 50px - 69px)
    // margin-bottom: 150px
  .gap
    height: $height-gap-1300

