@import 'styles/_theme.scss'

.card
  margin: 20px 0

.img
  width: 100px
  margin: 0 15px 1px 0
  border: solid 1px $color-secondary

.credit
  font-size: 12px
  line-height: 14px
  text-align: right
  color: #707070
  &::first-letter
    text-transform: capitalize

.title
  font-size: 16px
  font-weight: bold
  line-height: 22px
  margin: 0 0 10px 0
  color: $color-subheader

.author
  font-size: 12px
  line-height: 18px
  color: $color-text
