@import 'styles/_theme.scss'

.card
  margin: 35px 0 46px 0

.category
  font-size: 12px
  line-height: 18px
  text-align: right
  color: $color-category
  &::first-letter
    text-transform: capitalize
.title
  font-size: 16px
  font-weight: bold
  line-height: 20px
  margin: 9px 0 11px 0
  color: #3B78C3
  transition: all ease-in-out .3s
  &:hover
    color: #08487d
.description
  font-size: 14px
  line-height: 18px
  color: $color-text
.readmore
  font-size: 14px
  line-height: 18px
  margin-left: 5px
  cursor: pointer
  text-wrap: nowrap
  color: #3B78C3
  text-decoration: underline
  transition: all ease-in-out .3s
  &:hover
    color: #08487d
    text-decoration: none
