@import 'styles/_theme.scss'

.container
  margin: 0 80px
  &:last-of-type
    margin-bottom: 20px

.h2
  font-size: 22px
  line-height: 26px
  font-weight: bold
  color: $color-header
  margin: 60px 0 20px

.noresult
  margin: 50px auto
  text-align: center
  font-size: 18px
  line-height: 30px
  color: $color-header

.panelHeader
  cursor: pointer
  padding: 20px
  width: 100%
  border: 0
  text-align: left
  font-size: 30px
  line-height: 31px
  display: flex
  justify-content: space-between
  align-items: center
  margin: 0
  font-weight: 600

.panelContent
  display: flex
  flex-direction: column
  flex: auto

.header
  display: flex
  background-color: $color-header
  color: #fff
  font-size: 14px
  font-weight: 600
  padding: 10px 20px
  line-height: 17px

.body
  flex: auto
  overflow-y: auto

.row
  display: flex
  padding: 15px 20px
  color: $color-interactive
  font-size: 16px
  line-height: 18px
  flex: 1
  align-items: center

.row:nth-child(even)
  background-color: #F4F7FB

.row a
  font-size: 16px
  line-height: 20px
  text-decoration: none
  color: $color-interactive

.titleCol
  display: flex
  flex: 1

.completionCol
  width: 150px
  margin-left: 20px

@media (max-width: calc($width-desktop - 0.1px))
  .container
    margin: 0
    &:last-of-type
      margin-bottom: 20px

@media (max-width: calc($width-tablet - 0.1px))
  .container
    margin: 0 20px
    &:last-of-type
      margin-bottom: 20px
