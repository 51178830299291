@import '../main';

@mixin circularprogress-theme($name) {
  $theme: map-get($themes, $name);
  $colors: map-get($theme, 'colors');
  $action-color: map-get($colors, 'action');
  $primary-color: map-get($colors, 'primary');
  $tertiary-color: map-get($colors, 'tertiary');
  $secondary-color: map-get($colors, 'secondary');
  $default-color: map-get($colors, 'default');
  $spinner: map-get($theme, 'spinner');
  $text: map-get($theme, 'text');
  $text-primary-color: map-get($text, 'primary');
  $text-secondary-color: map-get($text, 'secondary');
  .loader-t-#{$name} {
    position: relative;
    margin: 0 auto;
  }

  .circular-t-#{$name} {
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    fill: map-get($spinner, 'fill');
  }

  .circular-animation {
    animation: rotate 2s linear infinite;
  }

  .path-t-#{$name} {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    stroke: map-get($spinner, 'stroke');
    transform: rotate(-90deg); // start stroke at top center
    transform-origin: bottom right;
  }

  .path-animation {
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  .progress-path-action-t-#{$name} {
    stroke: $action-color;
  }
  .progress-path-primary-t-#{$name} {
    stroke: $primary-color;
  }
  .progress-path-primary-text-t-#{$name} {
    stroke: $text-primary-color;
  }
  .progress-path-tertiary-t-#{$name} {
    stroke: $tertiary-color;
  }
  .progress-path-secondary-t-#{$name} {
    stroke: $secondary-color;
  }
  .progress-path-secondary-text-t-#{$name} {
    stroke: $text-secondary-color;
  }
  .progress-path-default-t-#{$name} {
    stroke: $default-color;
  }
  .progress-path-knockout-t-#{$name} {
    stroke: #ffffff;
  }
}
