@import 'styles/_theme.scss'

.box
  margin: 0 auto
  padding: 0
  max-width: 1480px
.content
  display: block
  margin: 0 auto
  padding: 80px 130px
  // max-width: 1920px
  min-height: 600px
  cursor: default
  transition: all ease-in-out .3s
.gap
  height: $height-gap
.back
  position: relative
  cursor: pointer
  width: fit-content
  bottom: 75px
  margin-left: 130px
  padding: 0
  border: none
  background: none
  &:hover
    text-decoration: none
.backTxt
  color: #979797
  font-size: 14px
  font-weight: bold
  line-height: 17px

@media (max-width: calc($width-desktop - 0.1px))
  .box
    max-width: 1040px
  .gap
    max-width: 1040px

@media (max-width: calc($width-tablet - 0.1px))
  .box
    max-width: 600px
  .gap
    max-width: 600px
    height: $height-gap-1300
  .content
    padding: 20px 20px 30px
  .back
    bottom: 17px
    margin-left: 20px
