@import 'styles/_theme.scss'

.box
  margin: 0 auto
  padding: 30px 80px
  h3
    font-size: 22px
    line-height: 26px
    font-weight: bold
    margin-bottom: 30px
    color: $color-header
  p
    font-size: 16px
    line-height: 20px

.content
  display: block
  padding: 78px 130px
  min-height: 100%

.textbox
  max-width: 830px
  position: inherit

.logoText
  font-size: 75px
  color: $color-logo
  margin-top: 0
  margin-left: -8px
  padding-top: 0
  line-height: 102px

.p
  font-size: 16px
  line-height: 20px
  color: $color-black
  margin-top: 0
  margin-bottom: 0px

.a
  color: $color-link
  transition: all ease-in-out .3s
.a:hover
  color: $color-link
  text-decoration: none

.input
  height: 38px
  width: 100%
  max-width: 100%
  border: 1px solid #CECECE
  border-radius: 5px
  box-shadow: none !important
.input:focus
  border: 2px solid #006dff

.inputG
  border: 1px solid green

.inputF
  border: 1px solid red

.tickbox
  margin-left: 15px

.submittedSuccessBox
  border: 1px solid #00c202
  width: 100%
  height: 38px
  line-height: 32px
  padding: 0 10px
  margin: 20px 0
  color: #00c202
  background-color: #e5f6e6

.submittedFailedBox
  border: 1px solid #e05852
  width: 100%
  height: 38px
  line-height: 32px
  padding: 0 10px
  margin: 20px 0
  color: #e05852
  background-color: #f7e6e6

.checkbox
  margin-bottom: -3px
  margin-right: 15px
  height: 28px
  width: 28px
  float: left
  border: 2px solid #C1C1C1

.err_span
  font-size: 15px
  margin: 0
  padding: 0
  color: #f00

.group:first-of-type
  margin-top: 30px

.form
  display: flex
  flex-direction: column
  margin-bottom: 40px
  gap: 20px
  input
    max-width: 100%
    min-width: 350px
    height: 38px
    border-radius: 5px
    padding-left: 8px 
    font-size: 16px

input[type=password]
  padding-bottom: 6px
input[type=token]
  position: absolute
  bottom: 18px
  height: 0.5px
  width: 304px
  border: 0
  outline: none
  z-index: -1

.group
  display: flex
  flex-direction: column
  gap: 5px
  label
    text-align: left
    font-size: 16px
    font-weight: bold
    letter-spacing: 0px

.btn
  display: flex
  align-items: center
  justify-content: center
  width: fit-content
  border: 1px solid #3b78c3
  background: #3b78c3
  border-radius: 20px
  margin-top: 5px
  height: 38px
  color: #fff
  font-size: 16px
  line-height: 20px
  padding: 12px 24px
  opacity: 1
  box-shadow: none
  transition: all ease-in-out .3s
  &:hover
    background: #064B69
    border: 1px solid #1185B9
  &:active
    background: #064B69
    border: 1px solid #1185B9
  &:disabled
    opacity: 0.5

.back
  margin-top: 30px
  padding: 0
  cursor: pointer
  width: fit-content
  border: none
  background: none

@media (max-width: calc($width-desktop - 0.1px))
  .box
    padding: 24px 40px

@media (max-width: calc($width-tablet - 0.1px))
  .box
    padding: 24px 20px
  .back
    position: relative

@media (max-width: $width-mobile)
  .box
    margin: 0 auto
    padding: 20px
  .content
    margin: 0
    padding: 30px 20px 30px
    min-height: 450px
  .logoText
    font-size: 45px
    line-height: 61px
  .h1
    font-size: 18px
    font-weight: bold
    margin-bottom: 20px
    line-height: 34px
  .h2
    font-size: 18px
    font-weight: bold
    margin-bottom: 20px
    line-height: 18px
    position: inherit
  .h3
    font-size: 16px
    font-weight: bold
    margin-bottom: 20px
    position: inherit
  .p
    font-size: 16px
    line-height: 22px
  .p1
    font-size: 16px
  .pcheckbox
    padding-top: 4px
    margin: 0 45px 0 38px
  .input
    height: 42px
    width: 100%
  .group:first-of-type
    margin-top: 20px
  .tickbox
    margin-left: 0
  .btn
    margin-top: 20px
    line-height: 22px
  .submittedSuccessBox
    width: 100%
    font-size: 16px
    height: 100%
    line-height: 1.8em
  .submittedFailedBox
    width: 100%
    font-size: 16px
    height: 100%
    line-height: 1.8em
  .input:focus
    border: 2px solid #e38d00 !important
